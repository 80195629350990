@import "../../css/theme-variables.scss";
.review-check {
  display: flex;
  min-width: 32px;
  flex-direction: row;
  align-content: center;

  svg {
    fill: $green;
    font-size: 22px;
  }
  .initials {
    font-size: 16px;
  }
}

.review-check-legacy {
  display: inline-block;
  min-width: 5ex;

  input {
    margin-right: 4px;
  }

  span {
    cursor: pointer;
  }
}
