@import "../../css/theme-variables.scss";

.form-container {
  border: 1px solid $grey-lighter;
  box-shadow: 1px 1px 10px $grey-lighter;
  padding: 2em;
  background-color: white;
  margin: 2em 0;
}

.interview-form-section {
  border: 1px solid black;
}
