@import "../../css/theme-variables.scss";
.modal-form {
  color: black;
  padding: 20px;
  &.add-event-form {
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box {
      width: auto;

      ul.react-datepicker__time-list {
        height: calc(195px + (1.7rem / 2) + 23px);
      }
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item {
      height: auto;
    }
    .react-datepicker__time-container {
      width: 100px;
    }
  }
  .input[disabled],
  .textarea[disabled] {
    border-color: white;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }

  .field {
    margin-bottom: 0.75em;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-input {
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    position: relative;
    vertical-align: top;
    border: 1px solid grey;
    width: 100%;
  }

  .first-half {
    padding-right: 5px;
  }

  .second-half {
    padding-left: 5px;
  }

  @media only screen and (max-width: $mediaDesktopDefault) {
    .second-half {
      padding-left: 0px;
    }

    .first-half {
      padding-right: 0px;
    }
  }

  .modal-select {
    width: 100%;

    select {
      width: 100%;
    }
  }

  .modal-title {
    font-size: $font-large;
    font-weight: bold;
  }

  .modal-ul {
    margin: 20px 0px;
  }

  .modal-padded-p {
    margin-bottom: 10px;
  }
  .modal-extra-padded-p {
    margin-bottom: 0.75em;
  }

  .modal-form-textarea {
    min-height: 200px;
  }
}
