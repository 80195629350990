@import "./theme-variables.scss";

a {
  // ie fix
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

.table.is-striped tbody tr:not(.is-selected) {
  &:nth-child(even) {
    background: transparent;
  }
  &:nth-child(odd) {
    background: tint($blue-lighter, 20%);
  }
  &:hover {
    background: tint($blue-light, 80%);
  }
}
.table.is-striped th {
  border-bottom: 1px solid $grey-light;
}
.table.is-striped td {
  border: 0px;
}

.is-5 {
  font-size: 1rem !important;
}
.is-6 {
  font-size: 0.9rem !important;
}
.is-3 {
  font-size: 1.75rem !important;
}
.tag:not(body) {
  text-transform: uppercase;
  background: transparent !important;
  color: black;
  border-width: 0px;
  font-weight: bold;
  text-align: left;
  width: auto;
  font-size: 14px;
  padding: 0px !important;
}
.tag.is-success {
  // border: 2px solid $green-light;
  color: $green !important;
}
.tag.is-warning {
  // border: 2px solid $yellow-light;
  color: $yellow !important;
}
.tag.is-danger {
  // border: 2px solid $red-light;
  color: $red !important;
}
// .tag,
// .tag.is-info,
// .tag:not(body).is-info {
//   color: $gray;
//   padding: 0px;
// }

.shaded-section {
  background: $blue-lighter;
}
.constrained-section {
  max-width: 1440px;
  margin: 0px auto;
  padding: 20px 20px 0px 20px;
  .media {
    justify-content: space-between;
    .media-left {
      flex-shrink: 1;
      .level {
        margin-bottom: 0px;
        .title-tag {
          margin-left: 20px;
        }
      }
      .page-title {
        margin-bottom: 0px;
        font-weight: bold;
      }
      .page-blurb {
        margin-bottom: 0px;
        margin-top: 5px;
        font-weight: normal;
        line-height: 1.25rem;
        max-width: 800px;
      }
    }
  }
}
.constrained-section.top {
  padding: 30px 20px 30px 20px;
}
.constrained-section.top.has-tabs {
  padding-bottom: 0px;
}
.react-datepicker-popper {
  z-index: 300 !important; //overriding library css
}

.activeContentContainer {
  padding-bottom: 60px;
}

// columns
.column,
.columns {
  margin: 0px;
  padding: 0px;
}
.columns:not(:last-child) {
  margin: 0px;
}

// button
.button.is-text:hover,
.button.is-text.is-hovered,
.button.is-text:focus,
.button.is-text.is-focused {
  background-color: $gray-lighter;
}

.title {
  font-weight: 500;
  color: black;
}

body,
button,
input,
select,
textarea {
  font-family: "DIN Next";
}

.deleteButton {
  float: right;
}
.deleteButton:hover {
  background: $red !important;
  color: white !important;
}

.editButton {
  float: right;
}
.editButton:hover {
  background: $blue !important;
  color: white !important;
}

.field-inline,
.field.field-inline {
  margin-bottom: 0;
}

input:disabled::placeholder {
  border-color: $gray-light !important;
  color: $gray !important;
}

/* Target IE10+ */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .modal {
    justify-content: flex-start;
  }
}
