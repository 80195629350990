.SortableTable {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.SortableTable thead th {
  text-align: left;
  color: #000000;
  font-size: 13px;
  line-height: 1.69;
  letter-spacing: -0.3px;
  white-space: nowrap;
}

.SortableTable thead th.sortable span {
  opacity: 0.4;
  transition: opacity 0.2s ease;
}
.SortableTable thead th.sortable span:hover {
  opacity: 0.8;
}

.SortableTable thead th.sortable {
  cursor: pointer;
  user-select: none;
  padding-right: 13px;
}

.SortableTable thead th.sortable.sorted {
  padding-right: 0px;
}

.SortableTable thead th.sorted span {
  opacity: 1;
}

.SortableTable thead th.header-datetime {
  min-width: 125px;
}

.SortableTable thead th.header-phone {
  min-width: 125px;
}

.SortableTable tbody tr {
  position: relative;
}

.MMTable tbody tr td {
  height: 80px;
}

.MMTable tbody tr td.iconCell {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 2px;
  width: 40px;
}

.MMTable tbody tr td .domainUnassigned {
  color: #999999;
}

.MMTable tbody tr td.dateCell {
  opacity: 0.5;
}

.MMTable tbody tr td.emailsReceivedCell {
  width: 120px;
}

.MMTable tbody tr td.dateCell {
  width: 90px;
}

.MMTable tbody tr td.emailCell {
  color: #3747bc;
  cursor: pointer;
}
/* would be nice to make even rows have a different background
.MMTable tbody tr:nth-child(even) {
  background: #f8fafb;
}
*/

.MMTable tbody tr td.emailCell .emailCellIcon {
  width: 12px;
  height: 14px;
  padding-right: 10px;
  margin-left: -22px;
  opacity: 0;
}

.MMTable tbody tr:hover td.emailCell .emailCellIcon {
  opacity: 1;
}

.MMTable tbody tr td.emailCell .emailCellContainer {
  color: #3747bc;
}

.MMTable tbody tr td.statusCell {
  width: 78px;
}

.MMTable .DomainIcon {
  height: 16px;
  width: 16px;
  margin-top: 4px;
}

.MMTable .dateCellMonth {
  color: #000000;
  font-size: 15px;
}

.MMTable .dateCellYear {
  color: #000000;
  opacity: 0.5;
  font-size: 12px;
  display: none;
}

.SortableTable .fa {
  background-repeat: no-repeat;
  background-size: 8px 8px;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: 5px;
}

.SortableTable .fa.fa-sort-desc {
  background-image: url(../assets/images/sort_desc.png);
}
.SortableTable .fa.fa-sort-asc {
  background-image: url(../assets/images/sort_asc.png);
}

.SortableTable .expandedParent {
  background-color: #ffffff;
  /* box-shadow: 0 -12px 40px 0 rgba(0, 0, 0, 0.12); */
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.12);
}

.SortableTable .expandedParent td,
.SortableTable .expandedChild td {
  border-bottom: none;
}

.SortableTable .expandedChild {
  background-color: #ffffff;
  /* box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.12); */
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.12);
}

.SortableTable .expandedChild td {
  height: inherit;
}

.SortableTable .expandedChild td > div {
  height: 216px; /* This comes from the measured height of ExpandedEmailRow. Flimsy but works. */
  background-color: #ffffff;
  margin-top: -24px; /* Cover the blurred border from the row above. Hacktastic. */
  animation-name: growHeight;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  overflow: hidden;
  padding-left: 20px;
  border-radius: 8px;
}

.SortableTable .expandedParent.disabledRow,
.SortableTable .expandedChild.disabledRow,
.SortableTable .expandedChild.disabledRow td > div {
  background-color: #ffefea;
}

.SortableTable .expandedParent.disabledRow,
.SortableTable .expandedChild.disabledRow,
.SortableTable .expandedChild.disabledRow td > div {
  background-color: #ffefea;
}

@keyframes growHeight {
  from {
    height: 0px;
  }

  to {
    height: 235px;
  }
}
