@import "../../css/theme-variables.scss";

.create-class-container {
  border: 1px solid $grey-lighter;
  box-shadow: 1px 1px 10px $grey-lighter;
  padding: 2em;
  background-color: white;
  margin-bottom: 2em;

  .class-form-content {
    padding: 2em;
  }
}
