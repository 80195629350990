.summaryTable {
  table {
    border: 1px solid #cccccc;
    width: 100%;

    td {
      border: 1px solid #cccccc;
    }
  }

  .labelCell {
    font-weight: bold;
    padding: 4px;
    text-align: right;
  }
  .valueCell {
    padding: 4px;
    text-align: right;
  }
}
