@import "../../css/theme-variables.scss";
.analyticsReportPage {
  padding: 24px;
  max-width: 1200px;
  margin: 0px auto;

  .header {
    overflow: hidden;
    background: white;
    .header-left {
      float: left;
      .title {
        font-size: 26px;
        font-weight: bold;
        color: $blue;
        margin: 0px;
      }
    }
    .reportPeriod {
      float: right;
      text-align: right;
      label {
        font-size: 13px;
        font-weight: bold;
        color: $black;
      }
      .inlineField {
        display: inline-block;
      }

      .inlineField + .inlineField {
        margin-left: 8px;
      }
    }
    &:after {
      clear: both;
    }
  }

  .uniqueClients {
    text-align: right;
  }

  .section {
    padding: 30px 0px 10px 0px;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
  }

  .sectionTitle {
    margin: 20px 0px 30px 0px;
    font-size: 1.4rem;
  }

  .detailToggle + .section {
    padding-top: 0px;
  }

  .detailToggle {
    margin-top: 30px;
    text-align: center;
    p {
      color: $blue;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .summaryColumns {
    // margin-top: 30px;
    .column {
      margin-right: 40px;
      padding-top: 10px;
      border-top: 1px solid #cccccc;
      overflow: hidden;
      &:last-child {
        margin-right: 0px;
      }
    }
    .label {
      font-size: 13px;
      font-weight: bold;
      color: black;
      width: 70%;
      float: left;
    }
    .value {
      text-align: right;
      font-size: 18px;
      width: 30%;
      float: right;
      // font-weight: bold;
    }
  }

  .overallSummary {
    .summaryColumns {
      .column {
        margin-right: 40px;
        padding-top: 10px;
        border-top: 1px solid #cccccc;
        &:last-child {
          margin-right: 0px;
        }
      }
      .label {
        font-size: 14px;
        font-weight: bold;
        color: black;
        width: 70%;
        float: left;
      }
      .value {
        text-align: right;
        font-size: 24px;
        width: 30%;
        float: right;
        // font-weight: bold;
      }
    }
  }
  .breakdownTable {
    td,
    th {
      text-align: right;
    }
    td:first-child,
    th:first-child {
      text-align: left;
    }
  }
  .SortableTable thead th {
    white-space: normal;
  }

  .downloadButton {
    margin-top: 5px;
    border-radius: 3px;
  }
}

@media only screen and (max-device-width: 768px) {
  .summaryColumns {
    .column {
      margin-right: 0px !important;
    }
  }
}

@media print {
  .analyticsReportPage {
    padding: 0px !important;
    max-width: auto !important;
    margin: auto !important;
    font-size: 0.8rem !important;

    .sectionTitle {
      font-size: 1.2rem !important;
    }
  }
  .detailToggle,
  .reportPeriod,
  .downloadButton {
    display: none;
  }

  .overallSummary {
    .value {
      font-size: 1.1rem !important;
    }
  }
}
