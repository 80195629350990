@import "../../../css/theme-variables.scss";
.column {
  margin: 20px 20px 10px 0px;
}

.title {
  font-weight: bold;
  color: $grey-light;
  margin-bottom: 2rem;
}
.timelineTitle {
  margin-top: 50px;
}

.timelineItemTitle {
  font-size: $font-medium;
}
.iconContainer {
  width: 20px;
  display: flex;
  justify-content: center;
}
.nowContainer {
  display: flex;
  align-items: center;
}

.nowTitle {
  margin-right: 0.5rem;
  font-size: 14px;
  top: -2px;
  position: relative;
  color: $grey;
  text-transform: uppercase;
  font-family: "Menlo", "Lucida Console", "Courier New", Courier, monospace;
}

.nowLine {
  flex-grow: 1;
  border-style: dashed none none;
  border-width: 1px;

  color: $grey-light;
}

.timelineDate {
  font-size: 14px;
  color: $grey;
  font-family: "Menlo", "Lucida Console", "Courier New", Courier, monospace;
}

.notification {
  display: block;
  padding: 0.75rem;
  background-color: $grey-lighter;
  margin-top: 10px;
  font-size: $font-small;
}
