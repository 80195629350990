@import "../../../css/theme-variables.scss";

.header {
  flex: 1;
  background-color: $gray-lighter;
  justify-content: space-between;
  background: $blue-navbar;
}

.logoBox {
  padding: 0px;
  line-height: 0px;
}

.logo {
  line-height: 0px;
  margin-left: 5px;
}

.userName {
  color: $blue-light;
}
.user {
  align-items: center;
  padding-right: 0.75rem; // matches bulma navbar.sass > .navbar-item; yep, not a variable
  p {
    color: black;
  }
}

.links {
  margin-left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.link {
  margin-right: 60px;
  &:last-child {
    margin-right: 0px;
  }
}

.navbar-item {
  margin-left: 15px;
}

.linkText,
.linkTextActive {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    color: $white;
  }
}
.linkText {
  color: $blue-light;
}

.linkTextActive {
  color: $white;
}

.dropdown {
  .dropdown-trigger button {
    background: transparent;
    color: $blue-light;
    border-color: $blue;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: 2.5em;
    padding-top: calc(0.375em - 1px);
    .icon {
      display: none;
    }
    &::after {
      border-color: #3b7abe;
      right: 1.125em;
      z-index: 4;
    }
    &::after,
    .navbar-link::after {
      border: 3px solid $blue;
      border-radius: 2px;
      border-right: 0;
      border-top: 0;
      content: " ";
      display: block;
      height: 0.625em;
      margin-top: -0.4375em;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: rotate(-45deg);
      transform-origin: center;
      width: 0.625em;
    }
  }
  .dropdown-menu {
    min-width: auto;
    left: auto;
    right: 0px;
    .dropdown-content {
      padding: 0.5rem;
      // bulma fix: dropdown makes the first item the label...
      // so hide the first item
      // https://github.com/couds/react-bulma-components/issues/126
      .dropdown-item {
        padding: 0;
        button {
          text-decoration: none;
        }
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
}

.statusSpace {
  text-align: left;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
.status-message {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 150px; /* Set a default minimum width */
  margin-left: -75px; /* Divide value of min-width by 2 */
  background-color: $blue-darker; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: -100px; /* 30px from the bottom */
  opacity: 0;
  transition: 0.7s all;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
.status-message.saving,
.status-message.saved {
  opacity: 1;
  bottom: 20px;
  visibility: visible; /* Show the snackbar */
}
