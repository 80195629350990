@import "../../css/theme-variables.scss";

.upload-icon {
  color: "#3b7abe";
}

.no-documents-p {
  margin-left: 10px;
  color: $grey-darker;
  font-size: 16px;
}

.participant-document-title {
  color: $grey-darker;
  font-size: 18px;
  font-weight: bold;
}

.participant-document-info {
  font-size: 12px;
  color: $grey-dark;
}

.participant-document-container {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid $grey-lighter;
}

.participant-document-container-appear {
  animation-name: example;
  animation-duration: 1s;
}

@keyframes example {
  from {
    border: 1px solid green;
  }
  to {
    border: 1px solid $grey-lighter;
  }
}

.participant-document-button-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.participant-document-button-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin-left: 10px;
  }
}
