@import "../../css/theme-variables.scss";

.participant-details-column {
  .show-edit {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .show-edit {
      display: inline-flex;
    }
  }
  .field {
    border: 1px solid $blue-lighter;
    padding: 5px;
    height: 70px;
  }

  .participant-details-textarea {
    height: 100%;
    width: 100%;
  }

  .react-datepicker-input {
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    position: relative;
    vertical-align: top;
    border: 1px solid grey;
    width: 100%;
  }
}

.profile-image-container {
  height: 280px;

  .edit-image-box {
    display: none;
    background-color: #797879;
    flex-direction: row;
    width: 125px;
    border-radius: 5%;
    padding: 5px;
    color: white;
    opacity: 0.9;
    position: relative;
    top: -45px;
    margin: 0px;

    p {
      margin: 0px;
    }
  }

  &:hover {
    .edit-image-box {
      display: flex;
      cursor: pointer;
    }
  }
}
