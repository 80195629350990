@import "./theme-variables.scss";

.bucket {
  margin: 20px 0px 60px 0px;
  .section-info {
    display: flex;
    line-height: 23px;
    margin-bottom: 15px;
    svg {
      margin-right: 10px;
    }
    .section-title {
      font-weight: bold;
      font-size: 22px;
    }
    .section-blurb {
      font-size: 16px;
    }
  }
  .table-container {
    margin-left: 25px;
    overflow: initial;
    .SortableTable {
      border: 1px solid $grey-light;
      border-right: 0px;
      border-left: 0px;
      box-sizing: border-box;
    }
  }
}
.bucket-NoAttendanceData {
  td {
    vertical-align: middle;
    select {
      font-size: 14px;
      height: auto;
    }
  }
}
