@import "../../css/theme-variables.scss";

.participant {
  .accountButtons {
    margin-left: 5px;
  }
  .title.is-6 {
    margin: 0px;
  }
  .title.is-3 {
    margin: 0px;
    font-weight: bold;
  }
  .link-accent {
    text-decoration: underline;
    color: $blue;
    cursor: pointer;
  }
  .accountInfoContainer {
    margin-top: 40px;
  }
  .accountInfoSection {
    margin-right: 15px;
    &:last-child {
      margin-right: 0px;
    }
    .subtitle.is-6 {
      border-bottom: 1px solid $gray-light;
      padding-bottom: 3px;
    }
    .title.is-5 {
      text-transform: capitalize;
    }
    &.column {
      .tag {
        top: -1.65rem;
        position: relative; // oof, couldn't use display block
      }
      input[type="checkbox"] {
        margin-top: -1.25rem;
        display: block;
        // style={{ marginTop: "-1.25rem", display: "block" }}
      }
    }
    input:disabled,
    .select:not(.is-danger):not(.is-warning):not(.is-success) select:disabled,
    .select.disabled:not(.is-danger):not(.is-warning):not(.is-success)::after {
      background: transparent !important;
      border-color: $gray-light !important;
    }
  }
  .input.search {
    border-color: $grey-light;
  }
}
$title-padding: 30px;

.risk-tabs.tabs.is-boxed {
  position: relative;
  top: 1px;
  margin: $title-padding 0px 0px 0px;
  ul {
    border-bottom: 0px;
  }
  a {
    color: $gray-dark;
    text-decoration: underline;
  }
  a:hover {
    background-color: transparent;
    border-bottom-color: transparent;
    color: $black;
  }
  li.is-active a,
  li.is-active span {
    text-decoration: none;
    color: black;
    font-weight: bold;
    border-color: tint($blue-light, 40%);
    border-bottom: 2px solid white;
  }
  li.is-active a:hover {
    background: white;
  }
}
