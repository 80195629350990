@import "../../css/theme-variables.scss";

.carePlan {
  padding-top: 30px;
  .title.is-4 {
    margin: 0px;
  }
  .obligationSelect select {
    border: 1px solid $blue;
    color: $blue;
  }
  .obligationSelect select:hover {
    color: $blue;
    background: white;
  }
  .obligationSelect .select:not(.is-multiple):not(.is-loading):hover::after {
    border-color: $blue;
    opacity: 1;
  }
  .obligationSelect .select:not(.is-multiple):not(.is-loading)::after {
    border-color: $blue;
    opacity: 0.5;
  }
  input:disabled,
  .select:not(.is-danger):not(.is-warning):not(.is-success) select:disabled,
  .select.disabled:not(.is-danger):not(.is-warning):not(.is-success)::after {
    background: transparent !important;
    border-color: $gray-light !important;
  }
  .checkbox-vertical {
    margin-bottom: 10px !important;
  }
}
