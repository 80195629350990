@import "../../css/theme-variables.scss";

.bookings-create-or-container {
  border: 1px solid $grey-lighter;
  box-shadow: 1px 1px 10px $grey-lighter;
  padding: 2em;
  background-color: white;
  margin-bottom: 2em;
}

.grey-row-booking-table {
  background-color: $grey-lighter !important;
}
