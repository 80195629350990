.defaultAnalyticsTable {
  table {
    tr {
      border-bottom: 1px solid #cccccc;
    }
    td,
    th {
      padding: 4px;
    }
  }

  .table-note-p {
    font-style: italic;
    font-size: 12px;
  }
}
