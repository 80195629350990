@import "../../css/theme-variables.scss";
.participant-search-input {
  .search {
    width: 240px;
  }
}

.participant-search-results-container {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  border-top: none;
  width: 100%;
  margin: 5px 0 0 0;
  background-color: white;
  z-index: 1000;
}

.participant-search-results-box {
  border-top: 1px solid #d9d9d9;

  :hover {
    background-color: $gray-lighter;
  }
}

.participant-search-participant {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}

.participant-search-text {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  color: black;
}

.participant-selected {
  background-color: $gray-lighter;
}
