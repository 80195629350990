@import "../../../../css/theme-variables.scss";
.participantTable {
  .tag {
    color: $gray;
  }
  .tag.is-success {
    color: $green;
  }
  .tag.is-warning {
    color: $yellow;
  }
  .tag.is-danger {
    color: $red;
  }

  .eventDateColumn {
    color: $gray-dark;
  }
  .tableIcon {
    font-size: 16px !important;
    top: 2px;
    position: relative;
    margin-right: 4px;
  }
  .tableIcon.highlight {
    fill: $blue;
  }
  .tableIcon.attended {
    fill: $green-light;
  }
  .tableIcon.unknown {
    fill: $gray-light;
  }
  .tableIcon.missed {
    fill: $red-light;
  }
}
