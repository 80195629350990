@import "../../css/theme-variables.scss";

.assessment-container {
  border: 1px solid $grey-lighter;
  margin: 30px;
  padding: 10px;
}

.assessment-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assessment-title {
  margin: 0;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
  color: black;
  @media print {
    font-size: 1em;
  }
}

.assessment-title-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.score-box {
  border: 1px solid black;
  padding: 5px;
}

.assessment-info-container-first {
  border: 1px solid black;
  margin: 0.5em 0.5em 0 0.5em !important;
  padding: 1em;
}

.assessment-info-container {
  border: 1px solid black;
  margin: 0.5em 0.5em 0 0.5em !important;
  padding: 1em;
}

.assessment-name-container {
  border: 1px solid black;
  margin: 1em;
  padding: 1em;
}

.assessment-name {
  text-align: center;
  font-weight: bold;
}

.assessment-score-box {
  border: 1px solid black;
  padding: 5px;
  min-width: 4em;
  text-align: center;
  margin-bottom: 0px !important;
}

.assessment-score-box-label {
  padding: 5px;
  min-width: 4em;
  text-align: center;
  margin-right: 30px;
  @media print {
    margin-right: 0px;
  }
}

.hide-on-print {
  @media print {
    display: none;
  }
}

.show-on-print-only {
  display: none;
  @media print {
    display: inline-block;
  }
}

.assessment-verification {
  margin: 0;
  padding: 0;
  text-align: center;
}
