@import "../../css/theme-variables.scss";

.case-analytics-section {
  margin: 0.5em;
  border: 1px solid $grey-dark;
  padding: 0.5em;
  border-radius: 2%;
}

.case-analytics-section-filler {
  margin: 0.5em;
  padding: 0.5em;
}

.case-analytics-section-filler-narrow {
  display: flex;
  flex-direction: row;
  margin: 0 0.5em;
  padding: 0 0.5em;
}

.case-analytics-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.case-analytics-row-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.case-analytics-result-box {
  border: 1px solid $blue;
  margin: 1em;
  padding: 1em;
  width: 225px;
  border-radius: 2%;
}

.case-analytics-results-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
}

.case-result-box-title {
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
}

.case-checkbox-container {
  display: flex;
  flex-direction: column;
  width: 225px;
}

.case-filters-title {
  color: $grey-dark;
  font-weight: bold;
  font-size: 1.5em;
}

#case-analytics-results {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
