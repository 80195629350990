.recommendation-actions {
  width: 900px;

  button {
    margin-right: 15px;
  }
}

.vertical-margin {
  margin: 15px 0px;
}

.recommendation-form {
  font-family: Arial, Helvetica, sans-serif;
  width: 900px;
  color: black;

  .signature-container {
    margin-top: 60px;
    display: flex;
    align-items: flex-start;

    .signature-display {
      font-family: "Dancing Script";
      position: absolute;
      top: -30px;
      left: 0;
      white-space: nowrap;
    }

    .mr {
      margin-right: 10px;
    }

    .signature-input {
      position: absolute;
      top: -12px;
      &::placeholder {
        font-style: italic; 
      }
    }

    .signature-line-container {
      flex-grow: 1;
      position: relative;
    }

    .signature-line {
      border-bottom: 1px solid black;
    }
  }

  .section-wrapper {
    margin: 5px 0px;
  }

  .citation {
    font-size: 10px;
    font-style: italic;
  }

  .save-button-row {
    margin: 20px 0px;
    text-align: right;
  }

  @media print {
    width: 180mm;

    .ant-radio-checked .ant-radio-inner {
      border-color: black !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: black;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: black;
    }

    .avoid-page-break {
      page-break-inside: avoid;
    }

    .page-break-before {
      page-break-before: always;
    }

    // antd checkboxes and radio buttons are funky when printed
    // hacking pseudo-elements to get checked things to show up
    .ant-checkbox-checked .ant-checkbox-inner {
      border: 1px solid black;
    }
    .ant-checkbox-checked .ant-checkbox-inner::before {
      content: "\25A0";
      transform: scale(2.5, 2.5);
      position: absolute;
      top: -4px;
      left: 3px;
    }
    .ant-radio-checked .ant-radio-inner {
      border: 1px solid black;
    }
    .ant-radio-checked .ant-radio-inner::before {
      content: "\2B24";
      position: absolute;
      top: -2px;
      left: -1px;
    }
  }

  ul li::before {
    content: "\25A1"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
  }

  .ul {
    list-style-type: none;
  }

  .paragraph-text {
    margin: 15px 0px;
  }

  .info-text {
    margin: 0px;
  }

  .section-title {
    font-weight: bold;
    text-decoration: underline;
  }

  table {
    border: 1px solid black;
    width: 100%;

    .row-grey {
      background-color: #cccccc;
    }

    @media print {
      .header-column {
        background-color: #175d8f;
        th {
          color: white;
          text-align: center;
        }
      }
    }

    .header-column {
      background-color: #175d8f;
      th {
        color: white;
        text-align: center;
      }
    }

    tr {
      border: 1px solid black;
    }

    td {
      padding: 0.25em;
    }

    .border-none {
      border: none;
    }

    .border-left {
      border-left: 1px solid black;
    }

    .center-text {
      text-align: center;
    }

    .bold-text {
      font-weight: bold;
    }

    .italic-text {
      font-style: italic;
    }

    .padding-none {
      padding: none;
    }

    .vertical-center {
      vertical-align: middle;
    }
  }

  .checkbox-container {
    margin: 15px 0px;
  }

  .checkbox-container-indented {
    margin: 5px 20px;

    @media print {
      // the recommendation section is very long, and it's tricky to get page breaks
      // to work properly in the middle of the radio group / checkboxes.
      // so, making text a little smaller to get everything to fit on one page when printing
      span {
        font-size: 11px;
      }
      .ant-checkbox-wrapper {
        line-height: 1.1;
      }

      .ant-checkbox-checked .ant-checkbox-inner::before {
        transform: scale(2.6, 2.6);
        top: -1px;
        left: 4px;
      }
    }
  }

  .ant-checkbox-wrapper {
    span {
      color: black !important;
    }
  }

  .ant-radio-wrapper {
    span {
      color: black !important;
    }
  }

  .small-table-container {
    margin: 0 auto;
    width: 400px;

    table {
      width: 400px;
      td {
        padding: 0.2em;
        text-align: center;
      }
    }
  }
}
