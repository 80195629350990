@import "../../../css/theme-variables.scss";

.header-nav-menu {
  .header-sub-menu {
    max-height: 600px;
    overflow: auto;
    background-color: white;
    padding: 10px;
    position: absolute;
    z-index: 100;
    font-weight: bold;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
    a {
      text-decoration: none;
    }
  }

  .sub-menu-item {
    color: black;
    margin-bottom: 10px;
  }
  .link-text {
    color: $blue-light;
    font-weight: bold;
    text-decoration: none;

    .triangle {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 10px;
      border-style: solid;
      border-width: 7px 4px 0 4px;
      border-color: $blue-light transparent transparent transparent;
    }
    &:hover {
      color: white;
      .triangle {
        border-color: white transparent transparent transparent;
      }
    }
  }

  .is-active {
    text-decoration: none;
    font-weight: bold;
    color: $white;
  }
  .menu-list a {
    padding: 0.25em 0.75em;
    font-size: 14px;
    font-weight: normal;
  }

  .is-active {
    font-weight: bold;
    color: $white;
  }
}
