.top > article .case-manager-select {
  margin-right: 0.75rem;
}

.top .title-tag {
  justify-content: flex-start;
}

.message-unread-count {
  display: flex;
}
