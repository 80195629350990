@import "../../css/theme-variables.scss";

.carePlanSection {
  // margin-top: 20px;

  .sectionHeader {
    padding: 10px;
    font-weight: bold;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    align-items: center;
  }
  .sectionHeader > .title {
    margin-bottom: 0px;
  }
  .complianceSelectLabel {
    position: relative;
    top: 6px;
    right: 6px;
  }
  .checkbox {
    float: right;
  }
  .checkbox {
    border: 1px solid $gray-lighter;
    padding: 5px 10px 5px 12px;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
  }
  .checkbox:hover {
    background: $gray-lighter;
  }
  .checkbox:hover {
    background: $gray-lighter;
  }
  .checkbox input {
    position: relative;
    top: -2px;
    right: 3px;
  }
  .label {
    font-size: 14px;
    margin-bottom: 0px;
    color: $gray-dark;
  }
  .carePlanItem {
    padding: 16px;
    background: $blue-lighter;
    border: 1px solid $gray-light;
    border-top: 3px solid $gray;
    margin-bottom: 0px;
    border-radius: 4px;
    &.expanded {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &.danger {
      border-top-color: $danger;
    }
    &.success {
      border-top-color: $success;
    }
    &.warning {
      border-top-color: $warning;
    }

    .react-datepicker__input-container input {
      width: 120px;
    }
    .button.is-text:hover,
    .button.is-text.is-hovered,
    .button.is-text:focus,
    .button.is-text.is-focused {
      background-color: white;
    }
    .complianceSelect {
      margin-right: 40px;
    }
  }
  .carePlanItemSetup {
    .column {
      margin-bottom: 10px;
      background: white;
      border: 10px solid $blue-lighter;
      border-top: 0px;
      padding: 15px 10px 15px 50px;
    }
    .columns:not(first-child) {
    }
    .title {
      font-size: 20px !important;
    }

    .obligationEventPast {
      background: $gray-lighter;
      border-bottom: 1px solid white;
    }
    .obligationEventGroup {
      background: $teal-lighter;
    }

    /* surely this is awful */
    .title.obligationDescription {
      margin-bottom: 16px;
    }
  }

  // editing
  .sub {
    overflow: hidden;
    background: $blue-lighter;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .obligation-details {
      padding: 20px 20px 20px 60px;
      margin-bottom: 0px;
      align-items: flex-start;
      .level-left {
        margin-right: 20px;
      }

      .level-left,
      .level-right {
        align-items: flex-start;
        flex-shrink: 1;
        .level-item {
          margin-right: 20px;
          flex-shrink: 1;
          &:last-child {
            margin-right: 0px;
          }
          label {
            border-bottom: 1px solid $gray-light;
          }
        }
      }
    }
  }
  .subExpanded {
    margin-bottom: 20px;
    border: 1px solid $gray-light;
    border-top: 0px;
  }
  .subCollapsed {
    height: 0px;
    margin-bottom: 20px;
  }

  .recurring-events-container {
    padding: 0px 0px 0px 25px;
  }
}

.obligationEvent {
  border-bottom: 1px solid $gray-lighter;
  padding: 5px 0px 5px 5px;
  margin: 0px !important;
  &:hover {
    background-color: $blue-lighter;
  }
  .upcoming {
    margin-left: 10px;
  }
  .label {
    margin: 0px 10px 0px 0px;
  }
}

.width-bounded-level-item {
  max-width: 50vw;
}
