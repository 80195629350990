@import "../../css/theme-variables.scss";

.participant-victims-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}

.participant-victims-box {
  min-width: 600px;
  margin: 1em 0;
}

.victim-box {
  border: 1px solid $grey-lighter;
  box-shadow: 1px 1px 10px $grey-lighter;
  padding: 2em;
  background-color: white;
  margin-bottom: 1em;
}
