.case-numbers-container {
  .show-edit {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .show-edit {
      display: inline-flex;
    }
  }
}
