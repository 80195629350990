@import "../../css/theme-variables.scss";

.modal-container {
  align-items: normal !important;

  .modal-card {
    margin-top: 50px; // tall modals get hard to see
  }

  .modal-card {
    overflow: unset;
  }
  .modal-content {
    overflow: auto;
  }

  .modal-section {
    background-color: $gray-lighter;
    padding: 0px;
  }
}
/* Target IE10+ */
@media all and (min-width: 769px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .modal-content,
  .modal-card {
    max-height: calc(100vh - 40px);
    width: 640px;
    margin: 0 auto;
    overflow: auto !important;
  }
}
