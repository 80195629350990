@import "../../css/theme-variables.scss";

.modal-confirmaton-page {
  .main {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .table-field-label {
    font-weight: bold;
  }

  .modal-title {
    font-size: $font-large;
    font-weight: bold;
  }

  .confirm-box {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .table-wrapper {
    background-color: $white;
    padding: 10px;
    width: 100%;
  }

  .edit-button {
    background: transparent;
    border: none;
    color: $blue;
    text-decoration: underline;
    font-size: $font-small;
  }

  table {
    width: 100%;
    background-color: $white;
  }

  td {
    padding: 5px;
    border-bottom: 1px solid $gray;
  }

  td[colspan="2"] {
    text-align: center;
    border: none;
  }
}
