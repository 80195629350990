@import "../../css/theme-variables.scss";

.broadcast {
  .button.is-text {
    background: transparent;
    color: $blue;
    .MuiSvgIcon-fontSizeSmall-8 {
      font-size: 18px;
    }
    span {
      margin-left: 5px;
    }
    &:hover {
      border: 1px solid $blue;
    }
    &:active {
      background: $blue;
      color: white;
    }
  }
  .sendingSoon {
    font-size: 16px;
    color: $red;
  }
  .section-empty {
    margin-left: 35px;
    color: $gray-dark;
    font-size: 20px;
  }

  .SortableTable .expandedParent {
    display: none;
  }
  .SortableTable .expandedChild,
  .SortableTable .expandedParent {
    background: white !important;
  }
  .SortableTable .expandedChild,
  .modal-wrapper {
    .column {
      padding-right: 10px;
    }
  }
  .modal-wrapper {
    margin-bottom: 60px;
    box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.12);
  }
  .SortableTable .expandedChild td {
    padding: 0px;
  }
  .SortableTable .expandedChild td > div {
    padding-left: 0px;
    height: auto !important;
    animation-name: a;
    animation-duration: 200ms;
    margin-top: 5px;
    overflow: visible;
    border-radius: 0px;
    padding-bottom: 15px;
  }
  .confirm-table td {
    border-width: 1px;
    min-width: 180px;
  }
  ol.things-to-consider li {
    margin: 5px 0px 5px 15px;
  }
}
