// Promise Theme

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

$black: #000;
$white: #fff;

///////////////////////////
// OLD COLORS
//
// $gray: #9eadba;
// $gray-dark: shade($gray, 30%);
// $gray-light: tint($gray, 40%);
// $gray-lighter: tint($gray, 87%);

// $blue: #4a6ada;
// $blue-dark: shade($blue, 30%);
// $blue-light: tint($blue, 40%);
// $blue-lighter: tint($blue, 87%);

// $green: #00af8b;
// $green-dark: shade($green, 30%);
// $green-light: tint($green, 40%);
// $green-lighter: tint($green, 87%);

// $red: #d3455b;
// $red-dark: shade($red, 30%);
// $red-light: tint($red, 40%);
// $red-lighter: tint($red, 87%);

// $yellow: #eea543;
// $yellow-dark: shade($yellow, 30%);
// $yellow-light: tint($yellow, 40%);
// $yellow-lighter: tint($yellow, 87%);

///////////////////////////
// *NEW* COLORS
//
$blue-lighter: #f7f9fa; /* Blue-Airy */
$blue-light: #b1c8e2; /* Blue-Light */
$blue: #3b7abe; /* Blue-Blue */
$blue-dark: #093a70; /* Blue-Dark */
$blue-darker: #032539; /* Blue-Shady */

$blue-navbar: $blue-dark;

$green-lighter: #f0f5ea; /* Green-Airy */
$green-light: #a5e06b; /* Green-Light */
$green: #6fbc22; /* Green-Green */
$green-dark: #4a890a; /* Green-Dark */
$green-darker: #394929; /* Green-Shady */

$grey-lighter: #f2f2f2; /* Grey-Airy */
$grey-light: #d7d7d7; /* Grey-Light */
$grey: #bfbfbf; /* Grey-Grey */
$grey-dark: #797879; /* Grey-Dark */
$grey-darker: #000000; /* Grey-Shady */

$gray-darker: $grey-darker;
$gray-dark: $grey-dark;
$gray: $grey;
$gray-light: $grey-light;
$gray-lighter: $grey-lighter;

$yellow-lighter: #fff4e5; /* Mustard-Airy */
$yellow-light: #fbcf91; /* Mustard-Light */
$yellow: #f5a800; /* Mustard-Mustard */
$yellow-dark: #ba8000; /* Mustard-Dark */
$yellow-darker: #705417; /* Mustard-Shady */

$red-lighter: #fceaea; /* Red-Airy */
$red-light: #f69d9f; /* Red-Light */
$red: #d02c2f; /* Red-Red */
$red-dark: #96090c; /* Red-Dark */
$red-darker: #4e1718; /* Red-Shady */

$teal-lighter: #f5fdff; /* Teal-Airy */
$teal-light: #bcdee8; /* Teal-Light */
$teal: #29bede; /* Teal-Teal */
$teal-dark: #1d90b1; /* Teal-Dark */
$teal-darker: #1d5362; /* Teal-Shady */

$primary: $blue;
$link: $blue;
$info: $gray;
$success: $green;
$warning: $yellow;
$danger: $red;
$dark: $gray-dark;
$light: $gray-lighter;
$text: $black;

$font-xsmall: 12px;
$font-small: 14px;
$font-medium: 18px;
$font-large: 22px;
$font-xlarge: 26px;

$mediaDesktopDefault: 767px;
