@import "../../css/theme-variables.scss";
.goal-task-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  font-size: 20px;
  font-weight: bold;
  padding-top: 20px;
}

.border-top {
  border-top: 1px solid $gray-light;
}

.goals-tasks-add {
  display: flex;
  justify-content: center;
}

.note-details {
  font-size: 12px;
}

.display-dates {
  font-size: 12px;
  margin: 0px;
}

.display-dates-label {
  font-size: 12px;
  font-weight: bold;
  margin: 0px;
}

.goals-tasks-remove {
  display: flex;
  justify-content: flex-end;
}

.add-item-container {
  margin: 0 0 20px 0;
}

.goal-task-remove-text {
  font-size: 12px;
  text-decoration: underline;
  color: $red;
  &:hover {
    cursor: pointer;
  }
}
.goals-tasks-add-button {
  margin-left: 10px;
}

.goals-tasks-completed .goals-tasks-label-select {
  text-decoration: line-through;
  color: $gray;
}

.level.goals-tasks-list-item {
  display: flex;
  align-items: flex-start;
  border-top: 1px solid $gray-lighter;
  padding: 15px 0px;
  margin-bottom: 0px;
  &:last-child {
    border-bottom: 1px solid $gray-lighter;
  }
  .level-left,
  .level-item {
    flex-shrink: 1;
    align-items: flex-start;
  }
  svg {
    fill: $green;
    margin: -3px 3px 0px 0px;
  }
}
